import { CreditRequestModal } from '../interfaces/CreditRequestInterfaces';
import { DialogActions, Button, Dialog, DialogContent,DialogTitle, IconButton,Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; 
import { useTranslation } from 'react-i18next';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useState } from 'react';
import InformationModal from './InformationModal';
import { useNavigate } from 'react-router-dom';
import { CreditRequestType } from '../enums/credit-request-types';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import useLogError from '../../../hooks/useLogError';

const SendCreditRequestModal = (props: CreditRequestModal) => {

  const { t } = useTranslation('createCreditRequest');
  const { sendCreditRequest,sendReturnToVendorCreditRequest } = useNswagClient();
  const navigate = useNavigate();
  const [openInformationDialog, setOpenInformationDialog] = useState(false);
  const [informationMessage, setInformationMessage] = useState(''); 
  const [informationTitle, setInformationTitle] = useState('');
  const [isResponseSuccessful, setIsResponseSuccessful] = useState(false);
  const [loading, setLoading] = useState(false); 
  const { logError } = useLogError();
  
  const handleSend = () => {
    setLoading(true);
    const returnedData = props.creditRequest;   
    if(props.requestType &&props.requestType === CreditRequestType.CreditRequest)
    {
      sendCreditRequest(returnedData)
        .then((result) => {
          props.onClose();
          setIsResponseSuccessful(result.success!);
          setOpenInformationDialog(true);
          if(!result.success)
          {  
            setInformationMessage(result.message!);
            setInformationTitle(t('modals.errorTitle'));
          }
          else{
            setInformationMessage(t('modals.creditRequestSendSuccessfully'));
            setInformationTitle(t('modals.successTitle'));
          }
        })
        .catch((error) => {
          logError(error);
        }).finally(()=>{
          setLoading(false);
        });
    }
    else{
      setLoading(true);
      sendReturnToVendorCreditRequest(returnedData)
        .then((result) => {
          props.onClose();
          setIsResponseSuccessful(result.success!);
          if(result.success)
          {  
            setOpenInformationDialog(true);
            setInformationMessage(result.message!);
          }
        })
        .catch((error) => { 
          logError(error);
        }).finally(()=>{ 
          setLoading(false);
        });
    } 
  };
  const handleCloseInformationDialog = () => {
    setOpenInformationDialog(false);
    if(isResponseSuccessful)
    {
      navigate('/store/po-receipt');
    }
  };
  return ( 
    <>{loading ? <LoadingWheel/> : (<><Dialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {t('modals.sendCreditRequestTitle')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="textMD"
          sx={{ my: 4, mx: 6 }}>
          {t('modals.sendCreditRequestWarning')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={props.onClose}>
          {t('modals.no')}
        </Button>
        <Button
          variant='primary'
          size="lg"
          onClick={handleSend}
        >
          {t('modals.yes')}
        </Button>
      </DialogActions>
    </Dialog><InformationModal open={openInformationDialog}
      onClose={handleCloseInformationDialog}
      message={informationMessage}
      title={informationTitle} /></>)}
    </>
  );
};

export default SendCreditRequestModal;