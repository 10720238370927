import { SelectChangeEvent } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useContext, useEffect, useRef, useState } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { AdjustPurchaseOrderRequestDto, BeginCreditReqRequest_LineItems, CalculateDiscrepanciesLine, GetConversionsResponseDto, Item, PurchaseOrderHeader, PutPurchaseOrderHeaderRequest, PutPurchaseOrderLineRequest, Uom, AddItemsInPurchaseOrderRequest, ReturnToVendorRequest, ReturnToVendorRequest_LineItems } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import { CreditRequestStatus, LineRecord } from '../interfaces/LineRecord';
import { ReceivedType } from '../enums/ReceivedType';
import { compare, exportFile } from '../../../utils';
import { ConfigurationName } from '../../../enums/ConfigurationName';
import useLogError from '../../../hooks/useLogError';
import { UnitConversion } from '../interfaces/UnitConversion';
import { useTranslation } from 'react-i18next';
import { AdjustPurchaseOrderAlterationLine } from '../interfaces/AdjustOrders';
import axios, { CancelTokenSource } from 'axios';
import { SelectableItem } from '../interfaces/SelectableItem';
import dayjs from 'dayjs';
import { alseaLACFranchises } from '../../../enums/FranchiseNames';
import { isReturnToVendorButtonVisibleForFranchise } from '../../../app/services/environment';

export const usePurchaseOrdersDetails = () => {
  const { addItemsToPurchaseOrder, getPurchaseOrder, hasOutStanding, purchaseordersPUT, calculatediscrepancies, getCreditRequestCutOff, beginCreditRequest, getConfiguration, getConversions, adjustPurchaseOrder, getItems, getUoms, downloadPurchaseOrderById, getFranchiseOrderTypeStatus, allowNewReturnToVendorRequest, getLocalTime, postReturnToVendor } = useNswagClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderHeader | undefined>();
  const [search, setSearch] = useState('');
  const [poLineSearch, setPoLineSearch] = useState('');
  const [appliedSearch, setAppliedSearch] = useState('');
  const [appliedPoLineSearch, setAppliedPoLineSearch] = useState('');
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isStockCheckModalOpen, setIsStockCheckModalOpen] = useState(false);
  const [isCreditRequestModalOpen, setIsCreditRequestModalOpen] = useState(false);
  const [isCreditRequestCutOffModalOpen, setIsCreditRequestCutOffModalOpen] = useState(false);
  const [snackBarDuration, setSnackBarDuration] = useState<number | null>(6000);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('error');
  const [note, setNote] = useState('');
  const [purchaseOrderNote, setPurchaseOrderNote] = useState('');
  const [lines, setLines] = useState<LineRecord[]>([]);
  const [noteId, setNoteId] = useState(-1);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof LineRecord>('itemNumber'); 
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isClickable, setIsClickable] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [oneClickActive, setOneClickActive] = useState(false);
  const [discrepancies, setDiscrepancies] = useState<CalculateDiscrepanciesLine[]>();
  const [cutOffTime, setCutOffTime] = useState<string>('');
  const [uomConversionsCache, setUomConversionsCache] = useState<Map<string, UnitConversion[]>>(new Map<string, UnitConversion[]>());
  const [isAdjusting, setIsAdjusting] =  useState<boolean>(false);
  const [isAdjustEnabled, setIsAdjustEnabled] =  useState<boolean>();
  const [showAdjustButton, setShowAdjustButton] = useState<boolean>(false);
  const [canAdjust, setCanAdjust] = useState<boolean>(false);
  const [triggerButtonHeaders, setTriggerButtonHeaders] = useState<boolean>(false);
  const [originalLines, setOriginalLines] = useState<LineRecord[]>([]);
  const [showAutosaveMessage, setShowAutosaveMessage] = useState<boolean>(false);
  const [lastFocusedItemId, setLastFocusedItemId] = useState<number | null>(null);
  const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timeout | null>(null);
  const [modifiedPOLines, setModifiedPOLines] = useState<Set<number>>(new Set());
  const [canStoreAddItemsToPO, setCanStoreAddItemsToPO] = useState<boolean>(false);
  const [isTaxRateConfigEnabled, setIsTaxRateConfigEnabled] = useState<boolean | undefined>(undefined);
  const [taxRate, setTaxRate] = useState<number>(0);
  const [totalCostPriceIncludingTax, setTotalCostPriceIncludingTax] = useState<number>(0);
  const [navigateToIndex, setNavigateToIndex] = useState<boolean>(false);
  const [isCreatingReturnToVendor, setIsCreatingReturnToVendor] = useState<boolean>(false);
  const [disableUomLac, setDisableUomLac] = useState<boolean>(false);

  const [isOverReceivingDisabled, setIsOverReceivingDisabled] = useState<boolean>(false);

  const [showOverReceivingWarningModal, setShowOverReceivingWarningModal] = useState<boolean>(false);
  const [cannotOverReceive, setCannotOverReceive] = useState<boolean>(false);
  const [cannotOverReceiveMap, setCannotOverReceiveMap] = useState<Map<number, boolean>>(new Map());
  const cannotOverReceiveRef = useRef<boolean>(false);

  const isAsc = order === 'asc';
  const { logError } = useLogError();
  const { t } = useTranslation('purchaseOrderDetails');
  
  const INACTIVITY_TIMEOUT = 60000; // 1 min on milliseconds

  useEffect(() => {
    cannotOverReceiveRef.current = cannotOverReceive;
  }, [cannotOverReceive]);

  useEffect(() => {
    loadData(); 
  }, [isTaxRateConfigEnabled]);

  useEffect(() => {
    getTaxRateConfigurationStatus();  
  }, []);
  
  useEffect(() => {
    getAdjustConfigurationStatus();
    showOneClickReciveButton();
    showAdjustVisibility();
    showReceiveButton();
    showReturnToVendorButton();
    allowEditTable();
    getConfigurationStatus();
    getAllowNewReturnToVendor();
    getIsReturnToVendorDateValid();
  }, [triggerButtonHeaders]);
    
  useEffect(() => {
    if (modifiedPOLines.size > 0 && !isReceiving && !cannotOverReceiveRef.current) {
      handleAutosaveMessage(false);
      startInactivityTimer();
    }
  }, [modifiedPOLines]);

  const startInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
    const timer = setTimeout(() => {
      if (modifiedPOLines.size > 0 && !cannotOverReceiveRef.current) {
        modifiedPOLines.forEach(lineId => {
          autosaveItem(lineId);
        });
      }
    }, INACTIVITY_TIMEOUT); 
    setInactivityTimer(timer);
  };

  const blocker = useBlocker(() => modifiedPOLines.size > 0 && !cannotOverReceiveRef.current);

  useEffect(() => {
    const beforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if (modifiedPOLines.size > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [modifiedPOLines]);

  useEffect(() => {
    if (navigateToIndex) {
      navigate('/create-po-receipt');
      if (blocker?.state === 'blocked') {
        blocker.proceed();
      }
      setNavigateToIndex(false);
    }
  }, [navigateToIndex, blocker]);
  
  const handleLeavePageModalCancel = () => {
    if (blocker?.proceed) {
      blocker.proceed(); 
    } };

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset(); 
    } };

  const handleLeavePageModalConfirm = () => {
    saveClicked();
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };



  const loadData = () => {
    setIsLoading(true);
    getPurchaseOrder(Number(id))
      .then((res) => {
        setPurchaseOrders(res.data);
        isPurchaseOrderDateValid(res.data?.deliveryDate ?? '');
        setPurchaseOrderNote(res.data?.note ?? '');

        const franchiseName = selectedStore?.franchiseName ?? '';
        const isAlseaLACFranchises = alseaLACFranchises.includes(franchiseName ?? '');

        if(isAlseaLACFranchises){
          getOrderTypeOverReceivingValue(res.data?.orderType, franchiseName);
          setDisableUomLac(true);
        }
        const linesFromApi = res.data?.purchaseOrderLines?.map((poLineChange) => {
          const quantToUse = poLineChange.receivedQuantity == 0 ? '' : String(poLineChange.receivedQuantity);
          if (isTaxRateConfigEnabled && (poLineChange.unitCostPrice || poLineChange.unitCostPrice === 0)) {
            calculateTotalCostPriceIncludingTax(poLineChange.unitCostPrice, poLineChange.expectedQuantity ?? 0);
          }
          return {
            id: poLineChange.id ?? -1,
            quantity: quantToUse,
            uom: isAlseaLACFranchises ? poLineChange.expectedUom ?? '' : poLineChange.receivedUom ?? '',
            note: poLineChange.note ?? '',
            originalQuantity: quantToUse,
            originalUom: poLineChange.receivedUom ?? '',
            originalNote: poLineChange.note ?? '',
            expectedUom: poLineChange.expectedUom ?? '',
            expectedQuantity: poLineChange.expectedQuantity,
            uomList: poLineChange.uomList,
            itemNumber: poLineChange.itemNumber,
            itemDescription: poLineChange.itemDescription,
            lineNumber: poLineChange.lineNumber,
            tariffCode: poLineChange.tariffCode,
            hasDiscrepancy: poLineChange.hasDiscrepancy,
            originalReason: poLineChange.reasonForCreditRequest,
            reasonForCreditRequest: poLineChange.reasonForCreditRequest,
          };
        });
        setLines(linesFromApi ?? []);
        setOriginalLines(linesFromApi ?? []);
      }).finally(() => {
        setIsLoading(false);
        setTriggerButtonHeaders(!triggerButtonHeaders);
      });
  };

  const getConfigurationStatus = () =>{
    const storeNumber = selectedStore?.storeNumber ?? '';
    const storeName= selectedStore?.franchiseName ?? '';
    getConfiguration(ConfigurationName.OneClickReceiveEnabled, storeNumber, storeName )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result: any) => {
        if (result.data.value === 'true') { 
          setIsClickable(true);
        } else {
          setIsClickable(false);
        }})
      .catch((error) => {
        logError(error);
      });
  }; 

  const poLineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPoLineSearch(event.target.value);
  };

  const searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleNoteButtonClick = (id: number, note: string) => {
    setNoteId(id);
    setNote(note);
    setIsNoteModalOpen(!isNoteModalOpen);
  };

  const closeModal = () => {
    setIsNoteModalOpen(false);
  };

  const closeConfirmModal = () => {
    setShowCopyModal(false);
    setIsConfirmOpen(false);
  };

  const closeAddItemModal = () => {
    setShowAddItemModal(false);
  };

  const confirmModal = () => {
    setIsConfirmOpen(false);
    checkOutstandingStockcheck();
  };

  const acceptStockCountModal = () => {
    setIsStockCheckModalOpen(false);

    if(isAdjustEnabled && isAdjusting) 
    {
      adjustPurchaseOrders();
    }
    else{
      receivePurchaseOrder();
    }
  };

  const checkDiscrepancies = () => {
    const newDiscrepancies: CalculateDiscrepanciesLine[] = [];
    for (const item of lines) {
      if (item.quantity != item.expectedQuantity || item.uom !== item.expectedUom) {
        const uom = item.uom ?? item.expectedUom;
        const receivedQuantity = isNaN(Number(item.quantity)) ? 0 : Number(item.quantity);
        const lineItem: CalculateDiscrepanciesLine = {
          itemNumber: item.itemNumber,
          expectedQuantity: item.expectedQuantity,
          expectedUOM: item.expectedUom,
          receivedQuantity: receivedQuantity,
          receivedUOM: uom,
        };
        newDiscrepancies.push(lineItem); 
      } 
    }  
    setDiscrepancies(newDiscrepancies); 
    calculatediscrepancies(selectedStore?.storeNumber!, newDiscrepancies).then((result)=>{ 
      if (result?.length > 0) { 
        getCreditRequestCutOff(purchaseOrders?.ewsOrderNumber!, selectedStore?.storeNumber).then((result)=>{
          const dateToCompare = new Date(result);  
          if (dateToCompare >= new Date()) {
            setIsCreditRequestModalOpen(true);
          }
          else
          {
            setCutOffTime(result);
            setIsCreditRequestCutOffModalOpen(true);
          }
        }); 
      }
    }).catch((error) => {
      logError(error);
    });
  }; 
  const closeStockCountModal = () => {
    setIsStockCheckModalOpen(false);
  };
  const closeCreditRequestModal = () => {
    setIsCreditRequestModalOpen(false);
    const beginCreditReqRequestLineItems: BeginCreditReqRequest_LineItems[] = discrepancies!.map(line => ({
      itemNumber: line.itemNumber ?? '',
      receivedQuantity: line.receivedQuantity ?? 0,
      receivedUOM: line.receivedUOM ?? '',
    }));
    const creditRequest = {
      ewsOrderNumber: purchaseOrders?.ewsOrderNumber!,
      items:beginCreditReqRequestLineItems,
      storeNumber: selectedStore?.storeNumber!,
    };
    beginCreditRequest(creditRequest).then(() =>
    {
      purchaseOrders && navigate(`/store/credit-request/${purchaseOrders.id}`);
    });

  };
  const closeCreditRequestCutOffModal = () => {
    setIsCreditRequestCutOffModalOpen(false);
    
  };
  const isEdited = (line: LineRecord | undefined) => {
    if (!line) return false;
    return modifiedPOLines.has(line.id) && (line.originalQuantity != line.quantity || line.originalUom != line.uom || line.originalNote != line.note);
  };

  const handleQuantityChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLines = lines.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        const convertedQty = Number(event.target.value);
        const actualQuantity = isNaN(convertedQty) ? 0 : Math.max(0, convertedQty);
        returnValue.quantity = actualQuantity.toString();
      }
      return returnValue;
    });
    setLines(newLines);
    const poLine = newLines.find(x => x.id == id);
    if (poLine) updateHasDiscrepancy(poLine);
    if (poLine?.id) {
      setModifiedPOLines(prev => { 
        const updatedSet = new Set(prev);
        if (!updatedSet.has(poLine.id)) {
          updatedSet.add(poLine.id);
        }
        return updatedSet;
      });
    }

  };
 
  const handlePOLineFocus = (itemId: number) => {
    if (!cannotOverReceiveRef.current && lastFocusedItemId !== null && lastFocusedItemId !== itemId && modifiedPOLines.has(lastFocusedItemId)) {
      autosaveItem(lastFocusedItemId);
    }
    setLastFocusedItemId(itemId);
  };

  const autosaveItem = (itemId: number) => {
  
    if (itemId) {
      save(lines, false, true);
      setModifiedPOLines(prev => {
        const newModifiedPOLines = new Set(prev);
        newModifiedPOLines.delete(itemId);
        return newModifiedPOLines;
      });
    }
    else {
      handleAutosaveMessage(false);
    }
  };
    
  const handleUomsChange = (id: number) => (event: SelectChangeEvent) => {
    const resetUomList = purchaseOrders?.purchaseOrderLines?.find(poLine => poLine.id === id)?.uomList;

    const newLines = lines.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        returnValue.uom = event.target.value;
        returnValue.uomList = resetUomList;
      }
      return returnValue;
    });

    setLines(newLines);
    
    const poLine = newLines.find(x => x.id == id);
    if (poLine) updateHasDiscrepancy(poLine);
    if (poLine?.id) {
      setModifiedPOLines(prev => {
        const updatedSet = new Set(prev);
        if (!updatedSet.has(poLine.id)) {
          updatedSet.add(poLine.id);
        }
        return updatedSet;
      });
    }
  };
  const closeOverReceivingWarning = () => {
    setShowOverReceivingWarningModal(false);
  };

  const handleReasonChange = (id: number, reason: string) => {
    const newLines = lines.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        returnValue.reasonForCreditRequest = reason;
      }
      return returnValue;
    });
    setLines(newLines);
  };

  const handleNotesChange = (id: number, note: string) => {
    const newLines = lines.map(a => {
      const returnValue = { ...a };
      if (a.id == id) {
        returnValue.note = note;
      }
      return returnValue;
    });
    setLines(newLines);
  };

  const searchClick = () => {
    setAppliedPoLineSearch(poLineSearch);
    setAppliedSearch(search);
  };

  const filteredPoLines = lines?.filter(x => x.lineNumber?.includes(appliedPoLineSearch) || appliedPoLineSearch === '')
    .filter(x => x.itemNumber?.toLocaleLowerCase().includes(appliedSearch.toLocaleLowerCase()) || x.itemDescription?.toLocaleLowerCase().includes(appliedSearch.toLocaleLowerCase()) || search === '');

  const sortedPoLines = filteredPoLines?.sort((a, b) => compare(a, b, orderBy, order));

  const isShowingTrafficCode = purchaseOrders?.purchaseOrderLines?.some(x => x.tariffCode);

  const handlePurchaseOrderNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPurchaseOrderNote(event.target.value);
  };

  const showCopyQuantityModal=()=> {
    if (isReceiving) {
      return;
    }
    setShowCopyModal(true);
  };

  const copyOrderQuantities = () => {
    setShowCopyModal(false);
    const updatedLines = lines.map(line => {
      const quantToUse = line.expectedQuantity == 0 ? '' : String(line.expectedQuantity);

      let newUomList = line.uomList;
      if (!line.uomList?.some(uom => uom.code === line.expectedUom)) {
        newUomList = line.uomList?.concat([{ code: line.expectedUom }]);
      }

      return { ...line, quantity: quantToUse, uom: line.expectedUom, isEdited: true, uomList: newUomList };
    });
    setLines(updatedLines);
    return updatedLines;
  };

  const closeSnackBar = () => {
    setIsSnackBarOpen(false);
  };

  const setSnackBarProps = (severity: 'error' | 'warning' | 'info' | 'success', message: string) => {
    severity === 'success' ? setSnackBarDuration(6000) : setSnackBarDuration(null);
    setIsSnackBarOpen(true);
    setSnackBarSeverity(severity);
    setSnackbarMessage(message);
  };

  const saveClicked = () => { 
    if(isAdjustEnabled && isAdjusting) {
      adjustPurchaseOrders();
    }
    else{
      save(lines, false);
    }
  };

  const addItemClicked = () => { 
    setShowAddItemModal(true);
  };

  const save = (linesToSave: LineRecord[], isReceived: boolean = false, isAutosaving = false) => {
    const someLinesFilled = linesToSave.some(line => line.uom != '');

    if (someLinesFilled) {
      const poLines: PutPurchaseOrderLineRequest[] = linesToSave.filter(x => x.uom != '').map(line => {
        return {
          id: line.id,
          receivedUom: line.uom,
          receivedQuantity: Number(line.quantity),
          note: line.note,
          isSelected: isEdited(line),
          creditedAmount: 0,
          expectedQuantity: line.expectedQuantity,
          reason: line.reasonForCreditRequest,
          expectedUom: line.expectedUom,
          itemNumber: line.itemNumber,
        };
      });

      const body: PutPurchaseOrderHeaderRequest = {
        packingSlipNumber: purchaseOrders?.packingSlipNumber,
        acceptedDate: new Date().toISOString(),
        note: purchaseOrderNote,
        isReceived: isReceived,
        receivedType: oneClickActive ? ReceivedType.OneClick : ReceivedType.Standard,
        putPurchaseOrderLineRequests: poLines,
      };
      if(!isAutosaving){
        setSnackBarProps('info', t('saving'));
        setIsProcessing(true); 
      }  
      purchaseordersPUT(purchaseOrders?.id ?? -1, body)
        .then(() => {
          if(isAutosaving){      
            handleAutosaveMessage(true);
          }
          else{
            setSnackBarProps('success', t('successSavingPO'));
            loadData();
            checkDiscrepancies();}
        })
        .catch((error) => {
          setSnackBarProps('error', t('unableSavingPO') + ' ' + error);
        })
        .finally(() => {
          setIsProcessing(false);
          setIsConfirmOpen(false);
        });
    }
    else {
      setSnackBarProps('warning', t('warningSavingPO'));
    }
  };

  const handleAutosaveMessage = (changesSaved : boolean) => {
    setShowAutosaveMessage(changesSaved);
  };

  const adjustPurchaseOrders = ()=> {
    if(!purchaseOrders?.isReceived){
      logError('Cannot adjust purchase order which has not already been received');
      return;
    }
    const poLines: AdjustPurchaseOrderAlterationLine[] = [];
    const changedLine = lines.filter(x=> (x.quantity !== x.originalQuantity) || (x.uom !== x.originalUom) || (x.note !== x.originalNote) || (x.reasonForCreditRequest !== x.originalReason));
    for(const line of changedLine){
      if(!line.quantity || parseFloat(line.quantity)<= 0 || !line.uom){
        setSnackBarProps('error', t('invalidData'));
      }else{
        const originalLine = purchaseOrders.purchaseOrderLines?.find(l => l.id == line.id);
        poLines.push({
          itemNumber: line.itemNumber,
          oldQuantity: originalLine?.receivedQuantity!,
          newQuantity: parseInt(line.quantity),
          oldQuantityUom: originalLine?.receivedUom!,
          newQuantityUom: line.uom,
          note: line.note,
          isSelected: false,
          sequenceNumber: 0,
          reasonForCreditRequest: line.reasonForCreditRequest,
        });
      }
    }
    if(poLines.length > 0){

      const purchaseOrderDataToSave: AdjustPurchaseOrderRequestDto = {
        alterations: poLines,
        receivedType: ReceivedType.Standard,
      };
      adjustPOR(purchaseOrderDataToSave);
    }
    else{
      setSnackBarProps('error', t('noLineEdited'));
      logError('There are no edited line');
    }
  };
  
  const adjustPOR = (purchaseOrderDataToSave: AdjustPurchaseOrderRequestDto) => {
    setSnackBarProps('info',t('saving'));
    setIsProcessing(true);   
    adjustPurchaseOrder(purchaseOrders?.id ?? -1, purchaseOrderDataToSave)
      .then(() => {
        setSnackBarProps('success', t('successSavingPO'));
        loadData();
        checkDiscrepancies();
      })
      .catch((error) => {
        setSnackBarProps('error', t('unableSavingPO') + ' ' + error);
      })
      .finally(() => {
        setIsProcessing(false);
        setIsConfirmOpen(false);
      });
  };

  const downloadClicked = () => {
    setIsLoading(true);
    downloadPurchaseOrderById(Number(id))
      .then((data) => {
        exportFile(data.data,data.headers?.['content-type'], data.fileName ?? '');
      })
      .catch((error) => {
        setSnackBarProps('error', t('downloadFailed') + ' ' + error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const receivePurchaseOrder = () => {
    let linesToSave = lines;

    if (oneClickActive) {
      linesToSave = copyOrderQuantities();
    }

    save(linesToSave, true);
  };

  const receiveClick = () => {
    setOneClickActive(false);
    const allLinesFilled = lines.every(line => line.uom != '');
    const allLinesHaveValue = lines.every(line => line.quantity != '');
    if (allLinesFilled && allLinesHaveValue) {
      setIsConfirmOpen(true);
    }
    if(!allLinesFilled) {
      setSnackBarProps('warning', t('warningUOMFilled'));
    }
    if(!allLinesHaveValue) {
      setSnackBarProps('warning', t('warningQTYFilled'));
    }
  };

  const returnToVendorClick = () => {
    setIsCreatingReturnToVendor(true);
    lines?.forEach((line) => {
      line.quantity = '0';
    });
  };
  const returnToVendorCancelClick = () => {
    setIsCreatingReturnToVendor(false);
    lines?.forEach((line) => {
      line.quantity = line.originalQuantity;
      line.uom = line.originalUom;
    });
  };

  const oneClickReceiveClick = () => {
    setOneClickActive(true);
    checkOutstandingStockcheck();
  };

  const checkOutstandingStockcheck = () => {
    hasOutStanding(selectedStore?.storeNumber).then((result) => {
      if (result.data) {
        // If there are outstanding stock checks, show the stock check modal
        setIsStockCheckModalOpen(true);
      } else {
        // If there are no outstanding stock checks, mark the purchase order as received
        acceptStockCountModal();
      }
    })
      .catch((error) => {
        logError(error);
      });

  };
  
  const isReceiving = purchaseOrders?.isReceived ?? true;
  
  const [showReceive, setShowReceive] = useState<boolean>();
  const [showOneClickReceive, setShowOneClickReceive] = useState<boolean>();
  const [isEditable, setIsEditable] = useState<boolean>();
  const [showReturnToVendor, setShowReturnToVendor] = useState<boolean>();
  const [isReturnToVendorPendingStatus, setIsReturnToVendorPendingStatus] = useState<boolean>();
  const [isReturnToVendorDateValid, setIsReturnToVendorDateValid] = useState<boolean>();
  const [rmaErrorMessage, setRmaErrorMessage] = useState<string>();
  
  const allowEditTable = () => {
    // When return to vendor part is implemented one more case for that button should be added here
    // add this or case ( || this.isCreatingReturnToVendor)
    if( purchaseOrders && (!purchaseOrders?.isReceived || isAdjusting)){
      setIsEditable(true);
    }else{
      setIsEditable(false);
    }
  };

  const showReceiveButton =() => {
    const canEdit  =  hasPermissionTo(['PurchaseOrderWrite']);
    if(canEdit && purchaseOrders && (!purchaseOrders?.isReceived || isAdjusting)){
      setShowReceive(true);
    }
    else{
      setShowReceive(false);
    }
  };

  const showReturnToVendorButton =() => {
    getConfiguration(ConfigurationName.ReturnToVendorEnabled, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((config: any) => {
        if (config.data.value === 'true' && isReturnToVendorButtonVisibleForFranchise(selectedStore?.franchiseName ?? '')) {
          setShowReturnToVendor(true);
        }
        else
          setShowReturnToVendor(false);
      })
      .catch((e) => logError(e));
  };

  const getAllowNewReturnToVendor = () => {
    allowNewReturnToVendorRequest(purchaseOrders?.id ?? 0)
      .then((result) => {
        setIsReturnToVendorPendingStatus(result);
      })
      .catch((e) => logError(e));
  };

  const getIsReturnToVendorDateValid = () => {
    getLocalTime(purchaseOrders?.id ?? 0)
      .then((result) => {
        if (showAdjustButton || isAdjusting) {
          setIsReturnToVendorDateValid(false);
          return;
        }

        const creditRequestIsRaised =
        purchaseOrders?.creditRequestStatus === CreditRequestStatus.SENT ||
        purchaseOrders?.creditRequestStatus === CreditRequestStatus.RECEIVED;

        let sentDateTime = result.data ?? purchaseOrders?.acceptedDate ?? '';
        let threshold = 720; //30 days

        if (creditRequestIsRaised) {
          sentDateTime = purchaseOrders?.creditRequestSentDateTime ?? '';
          threshold = 48;
        }

        let diff = dayjs().diff(dayjs(sentDateTime), 'hour');

        if (diff > threshold) {
          sentDateTime = result.data ?? purchaseOrders?.acceptedDate ?? '';
          threshold = 720;
          diff = dayjs().diff(dayjs(sentDateTime), 'hour');
        }

        setIsReturnToVendorDateValid(diff <= threshold);
      }).catch((e) => logError(e));
  };

  const submitReturnToVendor = (rmaNumber: string) => {
    const items = lines?.filter(x => x.uom !== '').map((x) => {
      const quantity = x.quantity ? +x.quantity : 0;
      const poLine: ReturnToVendorRequest_LineItems = {
        itemNumber: x.itemNumber ?? '',
        returnToVendorQuantity: quantity,
        returnToVendorUom: disableUomLac ? x.expectedUom ?? '' : x.uom ?? '',
      };

      return poLine;
    });

    const request: ReturnToVendorRequest = {
      customerRmaNumber: rmaNumber,
      purchaseOrderHeaderId: purchaseOrders?.id?.toString() ?? '',
      storeNumber: purchaseOrders?.storeNumber ?? '',
      items: items ?? [],
    };

    postReturnToVendor(request)
      .then((result) => {
        navigate('/store/credit-request/' + result.data?.purchaseOrderHeaderId);
      })
      .catch((e) => {
        logError(e);
        setRmaErrorMessage(e);
      });
  };
  
  const showOneClickReciveButton =() => {
    const canEdit  =  hasPermissionTo(['PurchaseOrderWrite']);
    if(canEdit && isClickable && purchaseOrders && !purchaseOrders?.isReceived){
      setShowOneClickReceive(true);
    }
    else{
      setShowOneClickReceive(false);
    }
    if(canEdit  && purchaseOrders && !purchaseOrders?.isReceived){
      setShowSave(true);
    }
    else{
      setShowSave(false);
    }
  };
  
  const handleSort = (orderBy: keyof LineRecord) => {
    const order = isAsc ? 'desc' : 'asc';
    setOrder(order);
    setOrderBy(orderBy);
  };

  const clearAll = () => {
    setSearch('');
    setPoLineSearch('');
    setAppliedPoLineSearch('');
    setAppliedSearch('');
  };

  const updateHasDiscrepancy = (poLine: LineRecord) => {
    if(!uomConversionsCache.has(poLine.itemNumber ?? '')) {
      setUOMConversions(poLine);
    } else {
      checkForDiscrepancy(poLine, undefined);
    }
  };

  const setUOMConversions = (poLine: LineRecord) => {
    getConversions(poLine.itemNumber, undefined, undefined, undefined, selectedStore?.storeNumber)
      .then((result: GetConversionsResponseDto) => {
        if(result?.conversions) {
          const newCache = new Map(uomConversionsCache);
          newCache.set(poLine.itemNumber ?? '', result.conversions as UnitConversion[]);
          setUomConversionsCache(newCache);
          checkForDiscrepancy(poLine, newCache);
        }
      }).catch((error) => {
        logError(error);
      });
  };

  const checkForDiscrepancy = (poLine: LineRecord, uomConversionsCacheParam: Map<string, UnitConversion[]> | undefined) => {
    setLines((prevValues) => {
      const index = prevValues.findIndex(x => x.id === poLine.id);

      if (index < 0) return prevValues;

      const item = prevValues[index];
      item.hasDiscrepancy = calculateDiscrepancy(item, uomConversionsCacheParam);
      return [...prevValues];
    });
  };

  const handleOverReceiving = (overReceiving: boolean, lineId: number) => {
    setCannotOverReceiveMap((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(lineId, overReceiving);
      const anyOverReceiving = Array.from(updatedMap.values()).some(value => value);
      setCannotOverReceive(anyOverReceiving);
      return updatedMap;
    });
    handleAutosaveMessage(false);

    setShowOverReceivingWarningModal(overReceiving);
  };

  const calculateDiscrepancy = (line: LineRecord, uomConversionsCacheParam: Map<string, UnitConversion[]> | undefined) => {
    const { quantity = 0, expectedQuantity = 0, uom, expectedUom, itemNumber } = line;

    const actualQuantity = isNaN(Number(quantity)) ? 0 : Number(quantity);
    if (uom === expectedUom) {

      const isOverReceiving = actualQuantity > expectedQuantity;
      handleOverReceiving(isOverReceivingDisabled && isOverReceiving, line.id);

      return actualQuantity < expectedQuantity;
    } 
    else {
      const uomConversionsCacheTemp = uomConversionsCacheParam ?? uomConversionsCache;
      const conversion = uomConversionsCacheTemp.get(itemNumber ?? '')?.find(x => x.fromUom === uom && x.toUom === expectedUom);
      if (conversion) {

        const convertedExpectedQuantity = expectedQuantity * (conversion.conversionNumber ?? 0);

        const isOverReceiving = actualQuantity > convertedExpectedQuantity;
        handleOverReceiving(isOverReceivingDisabled && isOverReceiving, line.id);
        
        return actualQuantity < convertedExpectedQuantity ;
      } else {
        handleOverReceiving(false, line.id);
        return false;
      }
    }
  };

  const isPurchaseOrderDateValid = (incomingDate:string | undefined) => {
    const deliveryDate  =  new Date(incomingDate ?? '').setHours(11, 0, 0, 0);
    const difference = getDifferenceBetweenDatesInHours(deliveryDate, new Date());
    setCanAdjust (difference < 45);
  };

  const showAdjustVisibility = ()=>{
    if(isAdjustEnabled && 
      purchaseOrders && 
      isReceiving &&
      !(purchaseOrders.creditRequestStatus === CreditRequestStatus.RECEIVED || purchaseOrders.creditRequestStatus == CreditRequestStatus.SENT ) &&
      canAdjust &&
      !isAdjusting){
      setShowAdjustButton(true);
    }else{
      setShowAdjustButton(false);
    }
  };

  const adjustClicked =() =>{
    setIsAdjusting(true);
    setTriggerButtonHeaders(!triggerButtonHeaders);
  };

  const cancelAdjust =() =>{
    setIsAdjusting(false);
    setLines(originalLines ?? []);
    setTriggerButtonHeaders(!triggerButtonHeaders);
  };

  const getAdjustConfigurationStatus = () =>{
  
    const storeNumber = selectedStore?.storeNumber ?? '';
    const storeName= selectedStore?.franchiseName ?? '';
    getConfiguration(ConfigurationName.PurchaseOrderAdjustmentsEnabled, storeNumber, storeName )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result: any) => {
        if (result.data.value === 'true' && hasPermissionTo(['PurchaseOrderAdjustmentsWrite'])) { 
          setIsAdjustEnabled(true);
        } else {
          setIsAdjustEnabled(false);
        }})
      .catch((error) => {
        logError(error);
        setIsAdjustEnabled(false);
      });

    getConfiguration(ConfigurationName.AddItemsInPoEnabled, storeNumber, storeName)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result: any) => {
        if (result.data.value === 'true' && hasPermissionTo(['PurchaseOrderAdjustmentsWrite'])) { 
          setCanStoreAddItemsToPO(true);
        } else {
          setCanStoreAddItemsToPO(false);
        }})
      .catch((error) => {
        logError(error);
      });
  }; 

  const getTaxRateConfigurationStatus  = async () =>{
    const storeNumber = selectedStore?.storeNumber ?? '';
    const storeName= selectedStore?.franchiseName ?? '';

    getConfiguration(ConfigurationName.TaxRatePercentage, storeNumber, storeName ) 
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result: any) => {
        const configValue = result?.data?.value;

        if (configValue) { 
          setIsTaxRateConfigEnabled(true);
          setTaxRate(configValue/100); //since the tax is in percentage format
        } else {
          setIsTaxRateConfigEnabled(false);
        }})
      .catch((error) => {
        logError(error);
        setIsTaxRateConfigEnabled(false);
      });
  }; 

  const getOrderTypeOverReceivingValue =(orderType : string | undefined, franchiseName : string | undefined) => {
    getFranchiseOrderTypeStatus(franchiseName, orderType)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result: any) => {
        setIsOverReceivingDisabled(result.data.overReceivingDisabled);
      })
      .catch((error) => {
        logError(error);
      });
  };


  const calculateTotalCostPriceIncludingTax = (unitCostPrice: number, expectedQuantity: number): void => {
    const totalCost = unitCostPrice * expectedQuantity;
    const totalWithTax = totalCost + (totalCost * taxRate);
    setTotalCostPriceIncludingTax(prevTotal => prevTotal + totalWithTax);
  };

  const getDifferenceBetweenDatesInHours = (startDate: number, endDate:Date) => {
    const start = new Date(startDate).getTime(); 
    const end = new Date(endDate).getTime();
    const differenceMs = Math.abs(end - start);
    return Math.floor(differenceMs / (1000 * 60 * 60));
  };

  const [isLoadingItemData, setIsLoadingItemData] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectableItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  const handleSearchTermChange = (value: string) => {
    if (value != '') {
      setSearchTerm(value);
      setIsLoadingItemData(true);
      setItems([]);

      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
      }

      cancelTokenSourceRef.current = axios.CancelToken.source();
    
      getItems(value, selectedStore?.storeNumber, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0, 10, undefined, cancelTokenSourceRef.current.token)
        .then(
          (result) => {
            setItems(result.data?.items ?? []);
            setIsLoadingItemData(false);
          },
        )
        .catch((error) => {      
          if (!axios.isCancel(error)) {
            logError(error);
            setIsLoadingItemData(false);
          } 
        });
    }
    else {
      setSearchTerm('');
      setItems([]);
    }
  };

  const possibleUOMs = async (storeNumber: string, itemNumber: string): Promise<Uom[]> => {
    let uomReturn: Uom[] = [];
    await getUoms(itemNumber, storeNumber, true, true)
      .then((results) => {
        const uoms = results.data?.uoms;
        uomReturn = uoms ?? [];
      })
      .catch((error) => logError(error));
    return uomReturn;
  };

  const itemSelected = async (items: Item[]) => {
    // Filter items that are not already in the selectedItems array
    const itemsNotIncluded: SelectableItem[] = await Promise.all(
      items
        .filter(item => !selectedItems.some(selectedItem => selectedItem.id === item.id))
        .map(async item => {
          const possibleUoms = await possibleUOMs(selectedStore?.storeNumber ?? '', item.itemNumber ?? '-1');
          return {
            ...item,
            possibleUoms,
            quantity: 0,
          };
        }),
    );
  
    // Keep items that are already in the selectedItems array and present in the new selection
    const itemsToKeep = selectedItems.filter(item => items.some(i => i.id === item.id));
  
    // Update selectedItems state with the combined array of kept and newly added items
    setSelectedItems([...itemsToKeep, ...itemsNotIncluded]);
  
    setSearchTerm('');
  };

  const handleUomChange = (id: number, value: string) =>{
    setSelectedItems(prevSelectedItems =>
      prevSelectedItems.map(item =>
        item.id === id ? { ...item, uom: value } : item,
      ),
    );
  };

  const handleModalQuantityChange = (id: number, value: string) =>{
    const convertedQty = Number(value);
    const actualQuantity = isNaN(convertedQty) ? 0 : Math.max(0, convertedQty);

    setSelectedItems(prevSelectedItems =>
      prevSelectedItems.map(item =>
        item.id === id ? { ...item, quantity: actualQuantity } : item,
      ),
    );
  };

  const handleCostPriceChange = (id: number, value: string) =>{
    const convertedPrice = Number(value);
    const actualPrice = isNaN(convertedPrice) ? 0 : Math.max(0, convertedPrice);

    setSelectedItems(prevSelectedItems =>
      prevSelectedItems.map(item =>
        item.id === id ? { ...item, costPrice: actualPrice } : item,
      ),
    );
  };
  
  const confirmAddItems = () =>{
    const request: AddItemsInPurchaseOrderRequest = {
      purchaseOrderHeaderId: purchaseOrders?.id ?? -1,
      storeNumber: selectedStore?.storeNumber ?? '',
      items: selectedItems.map(item => ({
        itemNumber: item.itemNumber ?? '',
        description: item.description ?? '',
        requestedQuantity: item.quantity ?? -1,
        uom: item.uom ?? '',
        costPrice: item.costPrice ?? -1,
      })),
    };

    addItemsToPurchaseOrder(request)
      .then(() => {
        closeAddItemModal();
        setSelectedItems([]);
        loadData();
      })
      .catch((e) => {
        setSnackBarProps('error', t('unableToAddItemToPO') + ' ' + e);
      });

  };

  return {
    downloadClicked,
    poLineChange,
    searchChange,
    handleNoteButtonClick,
    closeModal,
    closeStockCountModal,
    closeCreditRequestModal,
    closeCreditRequestCutOffModal,
    handleQuantityChange,
    handleUomsChange,
    handleReasonChange,
    handleNotesChange,
    sortedPoLines,
    isShowingTrafficCode,
    handlePurchaseOrderNoteChange,
    copyOrderQuantities,
    closeSnackBar,
    saveClicked,
    receiveClick,
    oneClickReceiveClick,
    returnToVendorClick,
    returnToVendorCancelClick,
    showReturnToVendor,
    isReturnToVendorPendingStatus,
    isReturnToVendorDateValid,
    isCreatingReturnToVendor,
    submitReturnToVendor,
    rmaErrorMessage,
    setRmaErrorMessage,
    isReceiving,
    id,
    purchaseOrders,
    isStockCheckModalOpen,
    isCreditRequestModalOpen,
    isCreditRequestCutOffModalOpen,
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity,
    note,
    noteId,
    purchaseOrderNote,
    search,
    poLineSearch,
    cutOffTime,
    isEdited,
    lines,
    isNoteModalOpen,
    order,
    orderBy,
    handleSort,
    isConfirmOpen,
    closeConfirmModal,
    acceptStockCountModal,
    checkDiscrepancies,
    isLoading,
    receivePurchaseOrder,
    isProcessing,
    searchClick,
    clearAll,
    canAdjust,
    cannotOverReceive,
    showOverReceivingWarningModal,
    showCopyModal,
    showCopyQuantityModal,
    showAdjustButton,
    showReceive,
    adjustClicked,
    isEditable,
    confirmModal,
    isClickable,
    isAdjusting,
    cancelAdjust,
    showOneClickReceive,
    showSave,
    addItemClicked,
    showAddItemModal,
    handleSearchTermChange,
    items,
    isLoadingItemData,
    closeAddItemModal,
    itemSelected,
    selectedItems,
    searchTerm,
    handleUomChange,
    handleModalQuantityChange,
    handleCostPriceChange,
    confirmAddItems,
    canStoreAddItemsToPO,
    showAutosaveMessage,
    handlePOLineFocus,
    handleLeavePageModalConfirm,
    handleLeavePageModalClose,
    handleLeavePageModalCancel,
    blocker,
    modifiedPOLines,
    isTaxRateConfigEnabled,
    totalCostPriceIncludingTax,
    closeOverReceivingWarning,
    disableUomLac,
  };
};