import { Box, Grid, Paper, SvgIcon, TextField, Typography, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { theme } from '../../../theme';
import { ImageX } from '../../../assets';
import { Item as ItemOM } from '../../../app/services/api/orderManagementClient';
import { Item, StockItem as ItemIM } from '../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { useProductDetailsContext } from '../context/ProductDetailsContext';
import { applyRoundAndFormat } from '../../../utils';
import { useSearchParams } from 'react-router-dom';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { UserContext } from '../../../components/shared/useUser';

type DetailsProps = {
  imData?: ItemIM;
  omData?: ItemOM;
  currencyCode?: string;
}

const Details: FC<DetailsProps> = ({ imData, omData, currencyCode }) => {
  const { t } = useTranslation('productDetails');
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const { minimumQuantity, setMinimumQuantity, isEditMode, isValid, setIsValid } = useProductDetailsContext();
  const [searchParams] = useSearchParams();
  const isOrderable = searchParams.get('orderable') === 'true';
  const [stockData, setStockData] = useState<Item>();
  const { getByItemNumber } = useNswagClient();
  const { selectedStore } = useContext(UserContext);
  const sku = searchParams.get('sku');

  useEffect(() => {
    getItem(sku ?? '');
  }, []);

  const onQuantityChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setMinimumQuantity(value);
      setIsValid(!(value === '0' || value === ''));
    }
  };

  const getItem = async (sku: string): Promise<void> => {
    const itemResponse = await getByItemNumber(sku, selectedStore?.storeNumber);
    setStockData(itemResponse.data);
  };

  return (
    <>
      <Paper sx={{ display: 'flex', backgroundColor: theme.palette.primary.main, borderRadius: '5px', width: '100%', height: '100%', my: 5, px: 10, py: 5 }}>
        <Grid container
          color={theme.palette.common.white}>
          <Grid item
            xs={12}
            pb={5}
          >
            <Typography
              variant='textMD'
              fontWeight='bold'
            >
              {imData?.description ?? omData?.name}
            </Typography>
          </Grid>
          <Grid item
            xs={12}
            md={4}
            lg={3}
          >
            <Box
              height='200px'
              width='200px'
              border={`1px solid ${omData?.thumbnailUrl ? 'transarent' : theme.palette.common.white}`}
              sx={{ backgroundColor: omData?.thumbnailUrl ? theme.palette.common.white : 'transparent', borderRadius: '5px' }}
              display='flex'
              flex={1}
              justifyContent={omData?.thumbnailUrl ? 'flex-start' : 'center'}
              alignItems='center'
            >
              {omData?.thumbnailUrl ? (
                <img alt='product-thumbnail'
                  width='100%'
                  height='100%'
                  src={omData.thumbnailUrl ?? 'https://placehold.co/60x60'}
                  style={{ objectFit: 'contain' }}
                />) : (
                <SvgIcon><ImageX /></SvgIcon>
              )}
            </Box>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            md={8}
            lg={9}
            pt={isMobile ? 8 : ''}
          >
            <Grid item
              xs={12}
              md={6}
              lg={7}>
              <InfoDetail label={isOrderable ? t('sku') : t('itemNumber')}
                value={imData?.itemNumber ?? omData?.sku ?? t('notAvailable')}
                height='25%' />
            </Grid>
            <Grid item
              xs={12}
              md={6}
              lg={5}>
              <InfoDetail label={t('shippableUOM')}
                value={stockData?.pickPackUom ?? stockData?.uom ?? t('notAvailable')}
                height='25%' />
            </Grid>
            <Grid item
              xs={12}
              md={6}
              lg={7}>
              <InfoDetail label={t('soh')}
                value={omData?.stockOnHand ?? t('notAvailable')}
                height='25%' />
            </Grid>
            <Grid item
              xs={12}
              md={4}
              lg={5}>
              <InfoDetail label={t('primaryUOM')}
                value={omData?.primaryUom ?? imData?.uom ?? t('notAvailable')}
                height='25%' />
            </Grid>
            <Grid item
              xs={12}
              md={6}
              lg={7}>
              <InfoDetail label={t('category')}
                value={imData?.primaryCategory ?? omData?.category ?? t('notAvailable')}
                height='25%' />
            </Grid>
            <Grid item
              xs={12}
              md={6}
              lg={5}>
              <InfoDetail label={t('primaryPerShippableUOM')}
                value={omData?.packSize ?? t('notAvailable')}
                height='25%' />
            </Grid>
            <Grid item
              xs={12}
              md={6}
              lg={7}>
              <InfoDetail label={t('unitCostPrice')}
                value={applyRoundAndFormat(imData?.costPrice ?? omData?.price ?? 0, currencyCode)}
                height='25%' />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container
        py={5}
        px={10}
        direction='column'
        xs={12}
        md={5}
        lg={5}
        rowGap={5}
      >
        <Grid item>
          <InfoDetail label={t('bomEnabled')}
            value={imData?.isBom ? t('enabled') : t('notEnabled')}
          />
        </Grid>
        <Grid item>
          <InfoDetail label={t('posEnabled')}
            value={imData?.isPosEnabled ? t('enabled') : t('notEnabled')}
          />
        </Grid>
        <Grid item>
          <InfoDetail label={t('stockItem')}
            value={imData?.isStockItem ? t('enabled') : t('notEnabled')}
          />
        </Grid>
        <Grid item>
          <InfoDetail label={t('saleItem')}
            value={stockData?.isSaleItem ? t('enabled') : t('notEnabled')}
          />
        </Grid>
        <Grid item>
          <InfoDetail label={t('status')}
            value={stockData?.status ?? t('notAvailable')}
          />
        </Grid>
        <Grid item>
          {isEditMode ? (
            <EditableInfoDetail label={t('minOrderQty')}>
              <TextField
                size='small'
                placeholder={imData?.minimumQuantity?.toString() ?? t('minQtyPlaceholder')}
                sx={{ pl: 2 }}
                value={minimumQuantity}
                onChange={onQuantityChange}
                error={!isValid}
                helperText={!isValid ? t('minQuantityValidationMessage') : ''}
              />
            </EditableInfoDetail>
          ) : (
            <InfoDetail label={t('minOrderQty')}
              value={imData?.minimumQuantity ?? Number(0).toFixed(2)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

type InfoDetailProps = {
  label: string;
  value: string | number | boolean;
  height?: string;
}

const InfoDetail = ({ label, value, height }: InfoDetailProps) => {
  return (
    <Box display='flex'
      height={height}
      flexDirection='row'
      alignItems='center'>
      <Typography variant='textSM'
        p={1}>{label}:</Typography>
      <Typography variant='textSM'
        p={1}>{value}</Typography>
    </Box>
  );
};

type EditableInfoDetailProps = {
  label: string;
  children: JSX.Element;
  height?: string;
}

const EditableInfoDetail = ({ label, children, height }: EditableInfoDetailProps) => {
  return (
    <Box display='flex'
      height={height}
      flexDirection='row'
      alignItems='baseline'
    >
      <Typography variant='textSM'
        p={1}
      >{label}:</Typography>
      {children}
    </Box>
  );
};

export default Details;