import { Typography, Grid, Tabs, Tab, Button, Box, Skeleton } from '@mui/material';
import { Item as ItemOM, client } from '../../app/services/api/orderManagementClient';
import { UserContext } from '../../components/shared/useUser';
import { useContext, useEffect, useState } from 'react';
import useNswagClient from '../../hooks/api/useNswagClient';
import { StockItem as ItemIM, PutStockRequest } from '../../app/services/api/generated';
import Details from './components/Details';
import History from './components/History';
import Cost from './components/Cost';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useLogError from '../../hooks/useLogError';
import { ProductDetailsProvider, useProductDetailsContext } from './context/ProductDetailsContext';
import { theme } from '../../theme';
import useCurrencyCodeConfiguration from '../../hooks/configurations/useCurrencyCodeConfiguration';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import BackButton from '../../components/shared/actions/BackButton';
import MessagesSnackbar from '../../components/shared/MessagesSnackbar';

const enum ProductTabEnum {
  Details = 1,
  History = 2,
  Cost = 3
}

const ProductDetails = () => {
  const { selectedStore } = useContext(UserContext);
  const { id } = useParams<{ id: string | undefined }>();
  const [searchParams] = useSearchParams();
  const sku = searchParams.get('sku');
  const isOrderable = searchParams.get('orderable') === 'true';
  const { updateStock, getOneStock } = useNswagClient();
  const { logError } = useLogError();
  const { t } = useTranslation('productDetails');
  const [tabValue, setTabValue] = useState<ProductTabEnum>(ProductTabEnum.Details);
  const [loading, setLoading] = useState(false);
  const [omData, setOmData] = useState<ItemOM>();
  const [imData, setImData] = useState<ItemIM>();
  const { isEditMode, setIsEditMode, minimumQuantity, resetMinimumQuantity, isValid } = useProductDetailsContext();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState<boolean>(false);
  const [showSubmitStatusMessage, setShowSubmitStatusMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && sku) {
      loadData(id, sku);
    }
    getCurrencyCodeConfiguration();
  }, [selectedStore]);

  const loadData = async (id: string, sku: string) => {
    try {
      setLoading(true);
      await loadItemData(sku);
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }

    try {
      setLoading(true);
      await getItemById(sku);
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  const loadItemData = async (sku: string): Promise<void> => {
    const itemResponse = await client.getItemBySku(selectedStore?.storeNumber, sku);
    setOmData(itemResponse.data);
  };

  const isCostHistoryTabEnabled = () => {
    return (isOrderable || imData?.isStockItem || imData?.isPosEnabled);
  };

  const getItemById = async (sku: string): Promise<void> => {
    const itemResponse = await getOneStock(sku, selectedStore?.storeNumber);
    setImData(itemResponse.data);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleStockChange = () => {
    if (selectedStore && imData?.itemNumber) {
      const updatedStock: PutStockRequest = {
        itemNumber: imData.itemNumber,
        storeNumber: selectedStore.storeNumber,
        minimumQuantity: Number(minimumQuantity),
      };
      updateStock(updatedStock).then(() => {
        resetMinimumQuantity();
        setIsSubmittedSuccessfully(true);
        if (id && sku) {
          loadData(id, sku);
        }
      })
        .catch((error) => {
          setIsSubmittedSuccessfully(false);
          setErrorMessage(error);
          logError(error);
        })
        .finally(() => {
          setShowSubmitStatusMessage(true);
        });
    }
  };

  const onUpdateStock = () => {
    setIsEditMode(false);
    handleStockChange();
  };

  const onCancel = () => {
    setIsEditMode(false);
    resetMinimumQuantity();
  };

  const handleGoBack = () => {
    navigate(-1);
  };


  return (
    <PageArea>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        py={5}
        px={8}
      >
        <Box
          display='flex'
          flexDirection='row'
          justifySelf='start'
          alignItems='center'
          sx={{ color: theme.palette.primary.main }}
        >
          <BackButton
            handleClick={handleGoBack}
            title={t('back')}
            isLink={false}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          flex={1}
          alignItems='start'
        >
          {loading ?
            <Skeleton variant="rounded"
              width={200}
              height={40} /> :
            <PageTitle>{(imData?.description ?? omData?.name) ?? ''}</PageTitle>
          }
          <Typography variant='textSM'>{t('subtitle')}</Typography>
        </Box>
        {tabValue === ProductTabEnum.Details && (
          isEditMode ? (
            <Box
              flexDirection='row'
              display='flex'
              justifyContent='flex-end'>
              <Grid item
                xs={12}
                mr={2}
              >
                <Button
                  variant='secondary'
                  onClick={onCancel}
                >
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item
                xs={12}
              >
                <Button
                  disabled={!isValid || minimumQuantity === ''}
                  onClick={onUpdateStock}>
                  {t('save')}
                </Button>
              </Grid>
            </Box>
          ) : (
            <Grid item
              xs={12}
              sx={{ textAlign: 'right' }}>
              <Button
                onClick={() => setIsEditMode(true)}
              >
                {t('edit')}
              </Button>
            </Grid>
          )
        )
        }
        <Box
          display='flex'
          flexDirection='column'
          flex={1}
          mt={tabValue === ProductTabEnum.Details ? 0 : 3}
        >
          <Grid container
            item
            xs={12}>
            <Grid item
              xs={12}
              md={8}>
              <Tabs value={tabValue}
                onChange={handleChange}
              >
                <Tab label={t('details')}
                  value={ProductTabEnum.Details} />
                {isCostHistoryTabEnabled() && <Tab label={t('history')}
                  value={ProductTabEnum.History} />}
                {isCostHistoryTabEnabled() && <Tab label={t('costs')}
                  value={ProductTabEnum.Cost} />}
              </Tabs>
            </Grid>
          </Grid>
          {tabValue === ProductTabEnum.Details &&
            <Details
              imData={imData}
              omData={omData}
              currencyCode={currencyCode}
            />
          }
          {tabValue === ProductTabEnum.History && <History currencyCode={currencyCode} />}
          {tabValue === ProductTabEnum.Cost && <Cost currencyCode={currencyCode} />}
        </Box>
        <MessagesSnackbar
          open={showSubmitStatusMessage}
          onClose={() => setShowSubmitStatusMessage(false)}
          message={isSubmittedSuccessfully ? t('successMessage') : t('errorMessage') + ' ' + errorMessage}
          severity={isSubmittedSuccessfully ? 'success' : 'error'}
          duration={3000}
        />
      </Box>
    </PageArea>
  );
};

const ProductDetailsPage = () => {
  return (
    <ProductDetailsProvider>
      <ProductDetails />
    </ProductDetailsProvider>
  );
};

export default ProductDetailsPage;