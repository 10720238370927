 
import { PurchaseOrderLine } from '../../../app/services/api/generated';
import { Button, Grid, MenuItem, Select, SelectChangeEvent, TableCell, TableRow, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material';
import { useSelectedTheme } from '../../../hooks/useSelectedTheme';
import { useState, useEffect, useContext } from 'react';
import { RtvReason, returnToVendorReasonsTranslationMap, parseRtvReason } from '../enums/return-to-vendor-reason';
import { Reason, creditRequestReasonsTranslationMap, getCreditRequestReasons, isReasonForCreditRequestSelectable, parseReason } from '../enums/reason';
import { UserContext } from '../../../components/shared/useUser';
import { CreditRequestTableProps } from './../interfaces/CreditRequestInterfaces';
import { useTranslation } from 'react-i18next';
import { CreditRequestType } from '../enums/credit-request-types';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';
import UploadIcon from '@mui/icons-material/Upload';

const CreditRequestTable = (props: Readonly<CreditRequestTableProps>) => { 
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const { selectedTheme: theme } = useSelectedTheme();
  const { selectedStore } = useContext(UserContext); 
  const [linedItems, setLinedItems] = useState<PurchaseOrderLine[]>(props.purchaseOrderLines); 
  const { t } = useTranslation('createCreditRequest'); 

  let translationMapToUse = returnToVendorReasonsTranslationMap;
  if (props.selectedRequest.type == CreditRequestType.CreditRequest) {
    translationMapToUse = creditRequestReasonsTranslationMap;
  }
  else {
    translationMapToUse = returnToVendorReasonsTranslationMap;
  }
  const handleOnRequestMedia = (row: PurchaseOrderLine) => {
    if (!props.onRequestMedia) {
      return;
    }
    props.onRequestMedia(row);
  };

  const isReasonForCreditRequestSelectable = (reason: string | Reason): boolean => {
    reason = parseReason(reason);
    if (selectedStore?.franchiseName === 'AMREST') {
      return reason === Reason.SHORTAGE || reason === Reason.OVERAGE;
    } else if (selectedStore?.franchiseName === 'AmRest Poland') {
      return reason === Reason.NO_PRODUCT_IN_DELIVERY || reason === Reason.EXCESS_INVOICE_ADJUSTMENT;
    }
    return reason !== Reason.OUT_OF_TEMPERATURE && reason !== Reason.OVERAGE;
  };

  const isReasonForReturnToVendorSelectable = (reason: string | RtvReason): RtvReason => {
    reason = parseRtvReason(reason);
    return reason;
  };

  useEffect(() => { 
    displayFetchedData();
    getCurrencyCodeConfiguration();
  }, [props.purchaseOrderLines]);

  const displayFetchedData = () => {
    setLinedItems(props.purchaseOrderLines);
  };

  const isPhotoRequired = (item: PurchaseOrderLine) => { 
    const reason = linedItems.find(x => x.itemNumber == item.itemNumber)?.reasonForCreditRequest; 
    const isRequired: boolean = props.selectedRequest.type === CreditRequestType.ReturnToVendor ? false :
      (props.evidenceRequired && reason === Reason.DAMAGE.toString() || reason === Reason.EXPIRED_STALE_PRODUCT.toString());
    
    return  isRequired;
  };
  
  const isPhotoRequiredForAnyItem = () => {
    for (const item of linedItems) {
      if (isPhotoRequired(item)) {
        return true;
      }
    }
    return false;
  };
  
  const handleCreditRequestChange = (event: SelectChangeEvent<string>, itemNumber: string) => {
    const newValue = event.target.value;

    const updatedItems = linedItems.map((item) => {
      if (item.itemNumber === itemNumber) {
        return {
          ...item,
          reasonForCreditRequest: newValue,
        };
      }
      return item;
    });

    setLinedItems(updatedItems);
    props.onChangeReasonUpdate(updatedItems.find(x=>x.itemNumber == itemNumber)!);

  };

  return ( 
    <Grid
      sx={{ width: '100%', py: '30px' }}
    >
      <Box sx={{
        border: '1px solid',
        borderColor: theme.palette.custom.gray[200],
      }}>
        {linedItems.length > 0 && <TableContainer component={'div'}>
          <Table
            size="small"
            aria-label="a table">
            <TableHead>
              <TableRow >
                <TableCell>
                  {t('itemTable.sku')}
                </TableCell>
                <TableCell>
                  {t('itemTable.itemName')}
                </TableCell>
                <>
                  {
                    props.selectedRequest.type === CreditRequestType.CreditRequest &&
                      <TableCell>
                        {t('itemTable.quantityOrdered')}
                      </TableCell>  
                  }
                </>
                <>
                  {
                    props.selectedRequest.type === CreditRequestType.CreditRequest &&
                      <TableCell>
                        {t('itemTable.quantityRecieved')}
                      </TableCell>  
                  }
                </> 
                <TableCell>
                  {t('itemTable.creditQuantity')}
                </TableCell>
                <TableCell>
                  {t('itemTable.creditAmount')}
                </TableCell>
                <TableCell>
                  {t('itemTable.reason')}
                </TableCell>
                <>
                  {  isPhotoRequiredForAnyItem() &&
                    <TableCell> 
                      {t('itemTable.media')}
                    </TableCell> 
                  }
                </>
              </TableRow>
            </TableHead>
            <TableBody>
              {linedItems.map((row: PurchaseOrderLine) => (
                <TableRow
                  key={row.id}
                >
                  <TableCell sx={{ alignItems: 'center' }}>
                    {row.itemNumber}
                  </TableCell>
                  <TableCell>
                    {row.itemDescription}
                  </TableCell>
                  <>
                    {
                      props.selectedRequest.type === CreditRequestType.CreditRequest ?
                        <TableCell>
                          {row.expectedQuantity} {row.expectedUom}
                        </TableCell> : null
                    }
                  </>
                  <>
                    {
                      props.selectedRequest.type === CreditRequestType.CreditRequest ?
                        <TableCell>
                          {row.receivedQuantity} {row.receivedUom ?? row.expectedUom}
                        </TableCell> : null
                    }
                  </>
                  <TableCell>
                    {row.creditedQuantity} {row.creditedUom}
                  </TableCell>
                  <TableCell> 
                    {<RoundAndFormatValue value={row.creditedAmount ?? 0}
                      currencyCode={currencyCode} />}  
                  </TableCell>
                  <TableCell >
                    <Select
                      labelId="label"
                      id="select-credit-request-Id"
                      disabled = {!props.isButtonVisible}
                      value={row.reasonForCreditRequest ?? getCreditRequestReasons(selectedStore?.franchiseName ?? '')[0].toString()}
                      onChange={(event) => handleCreditRequestChange(event, row.itemNumber!)}
                    >
                      {Object.entries(translationMapToUse)
                        .filter(([reasonKey]) =>
                          (props.selectedRequest && props.selectedRequest.type === 1)
                            ? isReasonForCreditRequestSelectable(reasonKey)
                            : isReasonForReturnToVendorSelectable(reasonKey),
                        )
                        .map(([reasonKey, translation]) => (
                          <MenuItem key={reasonKey}
                            value={reasonKey}>
                            {t(translation)}
                          </MenuItem>
                        ))}
                    </Select> 
                  </TableCell>
                  <TableCell> 
                    {isPhotoRequired(row) && (
                      <Button
                        sx={{ p: 2 }}
                        variant="primary"
                        size="md"
                        onClick={() => handleOnRequestMedia(row)}
                      >
                        {props.isButtonVisible ? (
                          <>
                            <UploadIcon />  
                            {t('itemTable.uploadImage')}  
                          </>
                        ) : (
                          t('itemTable.viewImages')
                        )}
                      </Button>
                    )}
                  </TableCell>
         
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      </Box>
    </Grid>
  );
};

export default CreditRequestTable;